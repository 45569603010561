import React from 'react';
import HeroSection from '../../components/HeroSection.js';
import HeroSectionThemeAlpha from '../../components/HeroSectionThemeAlpha.js';

import FeaturesSection from '../../components/FeaturesSection';
import HowItWorks from '../../components/HowItWorks';
import Testimonials from '../../components/Testimonial.js';
import CallToAction from '../../components/CallToAction';
import CourseComponent from '../../components/CourseComponent.js';
import { useFeatureValue } from '@growthbook/growthbook-react';

function HomePage({ facottryServerConfigState }) {
	console.log('HomePage :: ', facottryServerConfigState);

	const value = useFeatureValue('country_segment', 'fallback');
	console.log('HomePage :: country_segment :: ', value);

	const { HomePage } = facottryServerConfigState;
	return (
		<div className="home-page">
			{HomePage.HeroSectionTheme === 'HeroSectionThemeAlpha' ? (
				<HeroSectionThemeAlpha HeroSectionData={HomePage.HeroSection} />
			) : (
				<HeroSection HeroSectionData={HomePage.HeroSection} />
			)}
			<CourseComponent CourseComponentData={HomePage.CourseComponent} />
			<FeaturesSection FeaturesSectionData={HomePage.FeaturesSection} />
			<HowItWorks HowItWorksData={HomePage.HowItWorks} />
			<Testimonials TestimonialData={HomePage.Testimonials} />
			<CallToAction CallToActionData={HomePage.CallToAction} />
		</div>
	);
}

export default HomePage;

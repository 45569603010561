import React from 'react';

function DisplayAds() {
	// push html Directly
	let adText = ` <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1787789438018488"
        crossorigin="anonymous"></script> <ins class="adsbygoogle" style="display:block" data-ad-client="ca-pub-1787789438018488" data-ad-slot="7258917881"
        data-ad-format="auto" data-full-width-responsive="true"></ins>  <script>
        (adsbygoogle = window.adsbygoogle || []).push({});
    </script>`;

	//inline Style with 400px height

	return (
		<>
			<div className="DisplayAds Naveen ">
				<div dangerouslySetInnerHTML={{ __html: adText }} />
			</div>
		</>
	);
}

export default DisplayAds;

// BlogPage.js
import React from 'react';
import BlogLoader from './BlogLoader';
import './BlogPage.css';

const BlogPage = () => {
	return (
		<div className="blog-page">
			<h1>Factory Blog</h1>
			<p>
				Insights, tips, and stories from the world of feature flagging and software
				development. Stay updated with the latest trends and best practices.
			</p>
			<BlogLoader />
		</div>
	);
};

export default BlogPage;

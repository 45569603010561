// Footer.js
import React, { useState, useEffect } from 'react';

import './ChatBot.css';

const ChatBot = () => {
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		//TODO: i will check if the script is loaded
		setTimeout(() => {
			let isPromptlyLoaded = customElements.get('promptly-app-embed');
			if (typeof isPromptlyLoaded !== 'undefined') {
				setIsLoaded(true);
			}
		}, 5000);
	}, []);

	function loadLibrary() {
		const script = document.createElement('script');
		script.src = 'https://storage.googleapis.com/trypromptly-static/static/js/embed-v1.js';
		script.async = true;
		document.body.appendChild(script);
	}

	useEffect(() => {
		loadLibrary();
	}, []);

	return (
		{ isLoaded } && (
			<div className="chatbot">
				<promptly-app-embed
					published-app-id="4ffcca1d-1ff7-47b1-96c3-a9e0e6a8f6a4"
					chat-bubble="true"
					host="https://trypromptly.com"
				></promptly-app-embed>
			</div>
		)
	);
};

export default ChatBot;

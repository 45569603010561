import React, { useState } from 'react';
import './VideoModal.css'; // Make sure to create this CSS file for styling
import { summaryArray } from './summaryArray.js'; // Make sure to create this file for the summaryArray

const VideoModal = ({ isOpen, onClose }) => {
	if (!isOpen) return null;

	return (
		<div className="video-modal-overlay">
			<div className="video-modal-content">
				<button className="close-button" onClick={onClose}>
					X
				</button>
				<video
					className="video-player"
					controls={true}
					autoPlay
					muted={false}
					preload="auto"
					volume={1.0} // Full volume
				>
					<source src="./video.mp4" type="video/mp4" />
					Your browser does not support the video tag.
				</video>

				<div className="video-summary">
					{summaryArray.map((summary, index) => (
						<div key={index} className="summary-item">
							<p>{summary}</p>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default VideoModal;

// AboutUsPage.js
import React from 'react';
import './AboutUsPage.css';
import ArticleAds from '../../components/ArticleAds';
import DisplayAds from '../../components/DisplayAds';

const AboutUsPage = () => {
	return (
		<div className="about-us-page">
			<header className="about-header">
				<h1>About Facottry</h1>
				<p>Our mission, vision, and team</p>
			</header>

			<section className="mission-vision">
				{/* <ArticleAds /> */}
				<h2>Our Mission</h2>
				<p>
					At Facottry, our mission is to empower development teams with the tools they
					need to manage feature flags seamlessly, deploy confidently, and innovate
					faster. We strive to provide the most reliable and efficient feature flagging
					and remote configuration solutions.
				</p>
				<DisplayAds />
				<h2>Our Vision</h2>
				<p>
					We envision a world where development teams can focus on building and delivering
					amazing products without worrying about deployment risks. Our vision is to make
					feature flagging and remote configuration accessible and effortless for every
					development team.
				</p>
			</section>
			{/* <ArticleAds /> */}

			<section className="team">
				<h2>Our Team</h2>
				<div className="team-members">
					<div className="team-member">
						<img src="./images/arungupta.png" alt="Arun Gupta" />
						<h3>Arun Gupta</h3>
						<p>CEO</p>
						<p>
							Mr. Arun Gupta leads the team with a vision for innovative feature
							management solutions.
						</p>
					</div>
					<div className="team-member">
						<img src="./images/shobhit.png" alt="Shobhit" />
						<h3>Shobhit Jaiswal</h3>
						<p>CTO</p>
						<p>Shobhit is the tech genius behind our state-of-the-art platform.</p>
					</div>
					<div className="team-member">
						<img src="./images/manish.png" alt="Manish" />
						<h3>Manish</h3>
						<p>Head of Product</p>
						<p>
							Manish ensures our products meet the needs of modern development teams.
						</p>
					</div>
					{/* Add more team members as needed */}
				</div>
			</section>

			<section className="careers">
				<DisplayAds />
				<h2>Careers</h2>
				<p>
					Interested in joining the Facottry team? We are always looking for talented
					individuals to help us on our mission. Check out our open positions below:
				</p>
				<a href="/careers">View Open Positions</a>
			</section>
		</div>
	);
};

export default AboutUsPage;

// PricingPage.js
import React from 'react';
import './PricingPage.css';

const PricingPage = () => {
	return (
		<div className="pricing-page">
			<header className="pricing-header">
				<h1>Simple, Transparent Pricing</h1>
				<p>Choose a plan that fits your needs</p>
			</header>

			<section className="pricing-plans">
				<div className="pricing-plan">
					<h2>Free Tier</h2>
					<p className="price">Free</p>
					<ul>
						<li>Basic Feature Flags</li>
						<li>Limited Remote Configuration</li>
						<li>Community Support</li>
					</ul>
					<a href="/start-free-trial" className="pricing-link">
						Start Free Trial
					</a>
				</div>

				<div className="pricing-plan">
					<h2>Pro Tier</h2>
					<p className="price">$49/month</p>
					<ul>
						<li>Advanced Feature Flags</li>
						<li>Unlimited Remote Configuration</li>
						<li>Email Support</li>
						<li>Segmentation</li>
					</ul>
					<a href="/start-free-trial" className="pricing-link">
						Start Free Trial
					</a>
				</div>

				<div className="pricing-plan">
					<h2>Enterprise Tier</h2>
					<p className="price">Custom Pricing</p>
					<ul>
						<li>All Pro Features</li>
						<li>Custom Solutions</li>
						<li>Priority Support</li>
						<li>Onboarding Assistance</li>
					</ul>
					<a href="/request-demo" className="pricing-link">
						Request a Demo
					</a>
				</div>
			</section>
		</div>
	);
};

export default PricingPage;

import React from 'react';
import './InteractiveDemoPage.css';

const InteractiveDemoPage = () => (
	<div className="interactive-demo-page">
		<h1>Interactive Demo</h1>
		<p>
			Experience Facottry in action with our interactive demo. See how feature flagging and
			remote configuration can transform your development workflow.
		</p>

		<div className="demo-step">
			<h2>Step 1: Integrate Facottry</h2>
			<p>
				Learn how to seamlessly integrate Facottry with your existing systems and start
				managing feature flags.
			</p>
		</div>

		<div className="demo-step">
			<h2>Step 2: Create and Manage Feature Flags</h2>
			<p>
				Discover how to create, update, and manage feature flags with ease, ensuring smooth
				feature rollouts.
			</p>
		</div>

		<div className="demo-step">
			<h2>Step 3: Monitor and Analyze Performance</h2>
			<p>
				Use Facottry's powerful analytics to monitor feature performance and make
				data-driven decisions.
			</p>
		</div>
	</div>
);

export default InteractiveDemoPage;

import React from 'react';
import './SolutionsPage.css';

const SolutionsPage = () => {
	return (
		<div className="solutions-page">
			<header className="solutions-header">
				<h1>Solutions for Every Team</h1>
				<p>Tailored solutions to meet the unique needs of your development team</p>
			</header>

			<section className="use-cases">
				<div className="use-case">
					<img
						src="/images/continuous-deployment.png"
						alt="Continuous Deployment"
						className="use-case-image"
					/>
					<h2>Continuous Deployment</h2>
					<p>
						Facottry streamlines the deployment process, allowing you to release new
						features and updates continuously with confidence.
					</p>
				</div>

				<div className="use-case">
					<img
						src="/images/canary-deployments.png"
						alt="Canary Deployments"
						className="use-case-image"
					/>
					<h2>Canary Deployments</h2>
					<p>
						Deploy new features to a small subset of users before rolling them out to
						the entire user base, minimizing risk and ensuring stability.
					</p>
				</div>

				<div className="use-case">
					<img
						src="/images/beta-testing.png"
						alt="Beta Testing"
						className="use-case-image"
					/>
					<h2>Beta Testing</h2>
					<p>
						Test new features with a select group of users to gather feedback and
						identify issues before a full-scale release.
					</p>
				</div>

				<div className="use-case">
					<img
						src="/images/a-b-testing.png"
						alt="A/B Testing"
						className="use-case-image"
					/>
					<h2>A/B Testing</h2>
					<p>
						Compare different versions of a feature to determine which performs better
						and make data-driven decisions.
					</p>
				</div>
			</section>
		</div>
	);
};

export default SolutionsPage;

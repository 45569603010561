import React from 'react';

function Testimonials() {
	const testimonials = [
		{
			name: 'John Doe',
			title: 'CTO',
			company: 'TechCorp',
			quote: 'Facottry transformed our deployment process.',
			logo: 'user1.jpg',
		},
		{
			name: 'Jane Smith',
			title: 'Lead Developer',
			company: 'DevShop',
			quote: 'A must-have tool for modern development teams.',
			logo: 'user2.jpg',
		},
	];

	return (
		<section className="testimonials">
			<h2>Trusted by Leading Development Teams</h2>
			<div className="testimonials-list">
				{testimonials.map((testimonial, index) => (
					<div key={index} className="testimonial-item">
						<img src={testimonial.logo} alt={testimonial.company} />
						<p>"{testimonial.quote}"</p>
						<h4>{testimonial.name}</h4>
						<span>
							{testimonial.title}, {testimonial.company}
						</span>
					</div>
				))}
			</div>
		</section>
	);
}

export default Testimonials;

import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
	return (
		<footer className="footer">
			<div className="footer-container">
				<div className="footer-column footer-links">
					<div className="footer-links-column">
						<h3>Quick Links</h3>
						<ul>
							<li>
								<Link to="/">Home</Link>
							</li>
							<li>
								<Link to="/features">Features</Link>
							</li>
							<li>
								<Link to="/solutions">Solutions</Link>
							</li>
							<li>
								<Link to="/pricing">Pricing</Link>
							</li>
						</ul>
					</div>
					<div className="footer-links-column">
						<ul>
							<li>
								<Link to="/resources">Resources</Link>
							</li>
							<li>
								<Link to="/about-us">About Us</Link>
							</li>
							<li>
								<Link to="/contact-us">Contact Us</Link>
							</li>
							<li>
								<Link to="/careers">Career</Link>
							</li>
							<li>
								<Link to="/hiring">Hiring</Link>
							</li>
						</ul>
					</div>
				</div>
				<div className="footer-column footer-social">
					<h3>Follow Us</h3>
					<div className="footer-social-columns">
						<div className="footer-social-column">
							<ul>
								<li>
									<a
										href="https://www.linkedin.com/in/facottry-app-65b5872b5/"
										target="_blank"
										rel="noopener noreferrer"
									>
										LinkedIn
									</a>
								</li>
								<li>
									<a
										href="https://www.instagram.com/facottry/"
										target="_blank"
										rel="noopener noreferrer"
									>
										Instagram
									</a>
								</li>
								<li>
									<a
										href="https://x.com/facottryApp"
										target="_blank"
										rel="noopener noreferrer"
									>
										X (formerly Twitter)
									</a>
								</li>
								<li>
									<a
										href="https://medium.com/@facottryapp"
										target="_blank"
										rel="noopener noreferrer"
									>
										Medium
									</a>
								</li>
							</ul>
						</div>
						<div className="footer-social-column">
							<ul>
								<li>
									<a
										href="https://www.facebook.com/profile.php?id=61560100413224"
										target="_blank"
										rel="noopener noreferrer"
									>
										Facebook
									</a>
								</li>
								<li>
									<a
										href="https://stackoverflow.com/users/26577857/facottry-app"
										target="_blank"
										rel="noopener noreferrer"
									>
										Stack Overflow
									</a>
								</li>
								<li>
									<a
										href="https://www.quora.com/profile/FacOTTry-App"
										target="_blank"
										rel="noopener noreferrer"
									>
										Quora
									</a>
								</li>
								<li>
									<a
										href="https://facottrywebsite.vercel.app/"
										target="_blank"
										rel="noopener noreferrer"
									>
										Facottry Dashboard
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="footer-column footer-legal">
					<h3>Documents</h3>
					<ul>
						<li>
							<Link to="/privacy-policy">Privacy Policy</Link>
						</li>
						<li>
							<Link to="/terms-of-service">Terms of Service</Link>
						</li>
					</ul>
				</div>
			</div>
			<div className="footer-bottom">
				<p>&copy; 2024 Facottry. All rights reserved.</p>
			</div>
		</footer>
	);
};

export default Footer;

import React from 'react';
import './CaseStudiesPage.css';
import NotesPage from '../NotesPage';

const CaseStudiesPage = () => (
	<div className="case-studies-page">
		<h1>Facottry Case Studies</h1>
		<p>
			Discover how leading development teams are leveraging Facottry to enhance their
			workflows and achieve their goals.
		</p>

		<div className="case-study">
			<h2>Case Study: Continuous Deployment at TechCorp</h2>
			<p>
				TechCorp implemented Facottry to streamline their continuous deployment process,
				resulting in faster releases and improved stability.
			</p>
			<a href="#">Read more</a>
		</div>

		<div className="case-study">
			<h2>Case Study: A/B Testing at Innovate Inc.</h2>
			<p>
				Innovate Inc. used Facottry's A/B testing capabilities to optimize their product
				features, leading to higher user satisfaction and engagement.
			</p>
			<a href="#">Read more</a>
		</div>
		<div className="case-study">
			<h2>Notes App</h2>
			<p>
				Notes App is a simple note-taking application built using React. Users can create,
				edit, and delete notes, and the app saves the notes to local storage.
			</p>
			<a href="/notes">Read more</a>
		</div>
	</div>
);

export default CaseStudiesPage;

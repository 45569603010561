// FeaturesPage.js
import React from 'react';
import { useState } from 'react';
import './FeaturesPage.css';

import VideoModal from './../../components/VideoModal';

const FeaturesPage = (facottryServerConfigState) => {
	console.log('FeaturesPage :: ', facottryServerConfigState);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const openModal = () => setIsModalOpen(true);
	const closeModal = () => setIsModalOpen(false);
	function openVideoModal() {
		alert('Opening video modal');
		openModal();
	}

	return (
		<div className="features-page">
			<header className="features-header">
				<h1>Facottry Features</h1>
				<p>
					Empowering teams with powerful feature flagging and remote configuration tools
				</p>
			</header>

			<section className="features-list">
				<div className="feature-item" onClick={openVideoModal}>
					<img
						src="/images/feature-flags.png"
						alt="Feature Flags"
						className="feature-image"
					/>
					<h2>Feature Flags</h2>
					<p>
						Control which features are visible to users and gradually roll out new
						functionality with feature flags.
					</p>
					<a href="/demo" className="feature-link">
						Request a Demo
					</a>
				</div>

				<div className="feature-item">
					<img
						src="/images/remote-configuration.png"
						alt="Remote Configuration"
						className="feature-image"
					/>
					<h2>Remote Configuration</h2>
					<p>
						Easily update configuration settings without redeploying your application,
						ensuring flexibility and quick adjustments.
					</p>
					<a href="/demo" className="feature-link">
						Request a Demo
					</a>
				</div>

				<div className="feature-item">
					<img
						src="/images/segmentation.png"
						alt="Segmentation"
						className="feature-image"
					/>
					<h2>Segmentation</h2>
					<p>
						Target specific user segments with customized experiences based on behavior,
						geography, or other criteria.
					</p>
					<a href="/demo" className="feature-link">
						Request a Demo
					</a>
				</div>

				<div className="feature-item">
					<img
						src="/images/a-b-testing.png"
						alt="A/B Testing"
						className="feature-image"
					/>
					<h2>A/B and Multivariate Testing</h2>
					<p>
						Run experiments with different versions of features to identify the most
						effective options and optimize user experience.
					</p>
					<a href="/demo" className="feature-link">
						Request a Demo
					</a>
				</div>

				<div className="feature-item">
					<img
						src="/images/role-based-access.png"
						alt="Role-Based Access"
						className="feature-image"
					/>
					<h2>Role-Based Access and Security</h2>
					<p>
						Ensure that only authorized users have access to specific features and
						configurations with robust role-based access controls.
					</p>
					<a href="/demo" className="feature-link">
						Request a Demo
					</a>
				</div>
			</section>
			{isModalOpen && <VideoModal isOpen={isModalOpen} onClose={closeModal} />}
		</div>
	);
};

export default FeaturesPage;

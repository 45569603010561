import React from 'react';
import './EventsWebinarsPage.css';

const EventsWebinarsPage = () => (
	<div className="events-webinars-page">
		<h1>Facottry Events & Webinars</h1>
		<p>
			Join us for live events and webinars to learn more about Facottry and how it can benefit
			your development team.
		</p>

		<div className="event">
			<h2>Upcoming Webinar: Maximizing Feature Flags</h2>
			<p>
				Learn how to effectively use feature flags to improve your deployment strategy.
				Date: July 20, 2024.
			</p>
			<a href="#">Register now</a>
		</div>

		<div className="event">
			<h2>Event: Facottry User Conference 2024</h2>
			<p>
				Join us for our annual user conference to connect with other Facottry users and
				learn about the latest features and updates. Date: September 15, 2024.
			</p>
			<a href="#">Register now</a>
		</div>
	</div>
);

export default EventsWebinarsPage;

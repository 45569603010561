import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css'; // Ensure you have the appropriate CSS file

const Header = () => {
	return (
		<header className="header">
			<div className="logo">
				<Link to="/">Facottry</Link>
			</div>
			<nav className="navigation">
				<ul>
					<li>
						<Link to="/">Home</Link>
					</li>
					<li>
						<Link to="/features">Features</Link>
					</li>
					<li>
						<Link to="/solutions">Solutions</Link>
					</li>
					<li>
						<Link to="/pricing">Pricing</Link>
					</li>
					<li>
						<Link to="/resources">Resources</Link>
					</li>
					<li>
						<Link to="/about-us">About Us</Link>
					</li>
					<li>
						<Link to="/contact-us">Contact Us</Link>
					</li>
					<li>
						<Link to="/careers">Careers</Link>
					</li>
				</ul>
			</nav>
			<div className="cta-button">
				<Link to="/start-free-trial">Get Started</Link>
			</div>
		</header>
	);
};

export default Header;

// BlogLoader.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import EachBlog from './EachBlog';
import './BlogLoader.css';

const BlogLoader = () => {
	const [months] = useState(['2024-07', '2024-08']);
	const [expandedMonth, setExpandedMonth] = useState(null);
	const [blogs, setBlogs] = useState([]);
	const [selectedBlog, setSelectedBlog] = useState(null);

	useEffect(() => {
		if (expandedMonth) {
			const fetchBlogs = async () => {
				try {
					const response = await axios.get(`/blogs/${expandedMonth}/index.json`);
					setBlogs(response.data);
				} catch (error) {
					console.error('Error fetching blogs:', error);
					setBlogs([]);
				}
			};

			fetchBlogs();
		}
	}, [expandedMonth]);

	const handleMonthClick = (month) => {
		setExpandedMonth(expandedMonth === month ? null : month);
		setSelectedBlog(null); // Clear selected blog when month changes
	};

	const handleBlogClick = async (file) => {
		try {
			const response = await axios.get(`/blogs/${expandedMonth}/${file}`);
			setSelectedBlog(response.data);
		} catch (error) {
			console.error('Error fetching blog:', error);
			setSelectedBlog(null);
		}
	};

	return (
		<div className="blog-loader">
			<div className="left-panel">
				<h2>Months</h2>
				<ul>
					{months.map((month) => (
						<li
							key={month}
							onClick={() => handleMonthClick(month)}
							className={month === expandedMonth ? 'selected' : ''}
						>
							{month}
						</li>
					))}
				</ul>
				{expandedMonth && (
					<div className="accordion">
						{blogs.map((blog) => (
							<div key={blog.file} className="accordion-item">
								<div
									className="accordion-header"
									onClick={() => handleBlogClick(blog.file)}
								>
									{blog.title}
								</div>
								{selectedBlog && selectedBlog.file === blog.file && (
									<div className="accordion-body">
										<p>{blog.summary}</p>
									</div>
								)}
							</div>
						))}
					</div>
				)}
			</div>

			<div className="right-panel">
				{selectedBlog ? (
					<EachBlog blog={selectedBlog} />
				) : (
					<p>Select a blog to view details</p>
				)}
			</div>
		</div>
	);
};

export default BlogLoader;

import React from 'react';

function HowItWorks() {
	const steps = [
		{
			title: 'Integrate Facottry',
			description: 'Integrate with your existing workflow.',
			icon: './flow.jpg',
		},
		{
			title: 'Manage Feature Flags',
			description: 'Create and manage feature flags and configurations.',
			icon: './flow.jpg',
		},
		{
			title: 'Monitor Performance',
			description: 'Monitor and analyze feature performance.',
			icon: './flow.jpg',
		},
	];

	return (
		<section className="how-it-works">
			<h2>How It Works</h2>
			<div className="steps-list">
				{steps.map((step, index) => (
					<div key={index} className="step-item">
						<img src={step.icon} alt={step.title} />
						<h3>{step.title}</h3>
						<p>{step.description}</p>
					</div>
				))}
			</div>
		</section>
	);
}

export default HowItWorks;

import React from 'react';
import { Link } from 'react-router-dom';
/*
  The CallToAction component is a reusable component that displays a call to action message with two buttons.
  The component is used in the Home component to encourage users to start a free trial or request a demo.
*/
function CallToAction() {
	return (
		<section className="call-to-action">
			<h2>Ready to Transform Your Development Process?</h2>
			<p>Join thousands of teams using Facottry to deploy confidently and innovate faster</p>
			<div className="cta-buttons">
				<button>
					<Link to="/start-free-trial">Start Free Trial</Link>{' '}
				</button>
				<button>
					<Link to="/start-free-trial">Request a Demo</Link>{' '}
				</button>
			</div>
		</section>
	);
}

export default CallToAction;

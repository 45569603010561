// EachBlog.js
import React from 'react';
import './EachBlog.css';

const EachBlog = ({ blog }) => {
	return (
		<div className="each-blog">
			<h2>{blog.title}</h2>
			<h3 className="date-published">{blog.datePublished}</h3>
			<p>
				<strong>Author:</strong> {blog.author}
			</p>
			<div className="tags">
				<strong>Keywords:</strong>
				{blog.keywords.map((keyword, index) => (
					<span key={index} className="tag">
						{keyword}
					</span>
				))}
			</div>
			<p>{blog.mainStory}</p>
			<p>
				<strong>Summary:</strong> {blog.summary}
			</p>
			<p className="about-author">
				<strong>About the Author:</strong> {blog.aboutAuthor}
			</p>
		</div>
	);
};

export default EachBlog;

import React, { useEffect } from 'react';
import './HeroSection.css'; // Ensure you have a CSS file for styling
import { Link } from 'react-router-dom';

function HeroSection({ HeroSectionData }) {
	//  <p>{HeroSectionData.description}</p>
	//         <div className="hero-buttons">
	//           {HeroSectionData.buttons.map((button, index) => <button className="cta-button"><Link key={index} to={button.link} >{button.label}</Link></button>)}
	//         </div>
	//       </div>
	//       <div className="hero-image">
	//         <img src={HeroSectionData.image} alt="hero" />
	//       </div>
	//     </div>

	function stopHighlightServerSideData() {
		const allElements = document.querySelectorAll('[data-key]');
		allElements.forEach((eachElement) => {
			eachElement.classList.remove('editing-mode');
		});
	}

	function highlightAllServerSideData() {
		const allElements = document.querySelectorAll('[data-key]');
		allElements.forEach((eachElement) => {
			const key = eachElement.getAttribute('data-key');
			if (key.includes('HeroSectionData')) {
				eachElement.classList.add('editing-mode');
			}
		});
	}

	function saveChanges() {
		let HeroSectionData_temp = {};
		const allElements = document.querySelectorAll('[data-key]');
		allElements.forEach((eachElement) => {
			const key = eachElement.getAttribute('data-key');
			if (key.includes('HeroSectionData')) {
				const newValue = eachElement.innerHTML;
				console.log('Saving changes for key: ', key, ' with value: ', newValue);
				HeroSectionData_temp[key] = newValue;
			}
		});
		var HeroSectionData_final = { ...HeroSectionData, ...HeroSectionData_temp };
		console.log('Saving changes to server: ', HeroSectionData_final);
		stopHighlightServerSideData();
		document.body.contentEditable = false;
		window.EDITING_MODE = false;
		document.querySelector('.save-button').remove();
	}

	function renderSaveButton() {
		const saveButton = document.createElement('button');
		saveButton.innerHTML = 'Save Changes';
		saveButton.style.position = 'fixed';
		saveButton.style.bottom = '10px';
		saveButton.style.left = '100px';
		saveButton.style.zIndex = '1000';
		saveButton.style.padding = '10px';
		saveButton.style.border = '1px solid aqua';
		saveButton.style.backgroundColor = 'green';
		saveButton.classList.add('save-button');
		saveButton.onclick = saveChanges;
		document.body.appendChild(saveButton);
	}

	/**
	 * Toggles the editing mode for the HeroSection component.
	 * If the editing mode is not enabled, it enables it by setting the window.EDITING_MODE to true,
	 * highlighting all server-side data, making the document body content editable, and rendering a save button.
	 */
	function toggleEditingMode() {
		if (!window.EDITING_MODE) {
			window.EDITING_MODE = true;
			highlightAllServerSideData();
			document.body.contentEditable = true;
			renderSaveButton();
		}
	}

	return (
		<div className="hero-section">
			<div className="hero-content">
				<h1 data-key="HeroSectionData.heading">{HeroSectionData.heading} </h1>
				<p data-key="HeroSectionData.description">{HeroSectionData.description}</p>
				<div className="hero-buttons">
					{HeroSectionData.buttons.map((button, index) => {
						<button
							className="cta-button"
							key={button.label}
							data-key="HeroSectionData.buttons"
						>
							<Link key={index} to={button.link}>
								{button.label}
							</Link>
						</button>;
					})}
				</div>
			</div>
			<div className="hero-image">
				<img data-key="{HeroSectionData.image" src={HeroSectionData.image} alt="hero" />
			</div>
			<button className="edit-button" onClick={toggleEditingMode}>
				Edit
			</button>
			;
		</div>
	);
}

export default HeroSection;

import React, { useState } from 'react';
import './StartFreeTrial.css'; // Ensure you have the appropriate CSS file

const StartFreeTrial = () => {
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		company: '',
	});

	const handleChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		// Add form submission logic here (e.g., send data to an API)
		console.log('Form submitted:', formData);
	};

	return (
		<div className="start-free-trial">
			<h1>Start Your Free Trial</h1>
			<p>Experience Facottry's powerful features with no commitment.</p>
			<form onSubmit={handleSubmit}>
				<label>
					Full Name:
					<input
						type="text"
						name="name"
						value={formData.name}
						onChange={handleChange}
						required
					/>
				</label>
				<label>
					Email Address:
					<input
						type="email"
						name="email"
						value={formData.email}
						onChange={handleChange}
						required
					/>
				</label>
				<label>
					Company Name:
					<input
						type="text"
						name="company"
						value={formData.company}
						onChange={handleChange}
					/>
				</label>
				<button type="submit">Start Free Trial</button>
			</form>
		</div>
	);
};

export default StartFreeTrial;

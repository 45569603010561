import React, { useState } from 'react';
import './CourseComponent.css';

const defaultImage = 'https://via.placeholder.com/150'; // URL of the default image
const courseLinks = [
	'https://facottry.coursify.me/courses/integrate-facottry-in-react-website', // Link for the first course
	'https://facottry.coursify.me/courses', // Link for the second course
];

const CourseComponent = () => {
	// Array of courses with detailed descriptions
	const CourseComponentData = [
		{
			title: 'Introduction to Facottry',
			description:
				'Learn how to integrate Facottry into your website. This course covers the fundamentals of using Facottry, including setup, configuration, and best practices for implementation.',
			image: '/images/segmentation.png', // Example URL
		},
		{
			title: 'Advanced A/B Testing',
			description:
				"Dive deep into advanced A/B testing techniques. This course will teach you how to design, execute, and analyze complex A/B tests to optimize your website's performance and user experience.",
			image: '/images/a-b-testing.png', // Example URL
		},
	];

	// State to manage courses
	const [courses, setCourses] = useState(CourseComponentData);

	// Function to handle image loading error
	const handleImageError = (e) => {
		e.target.src = defaultImage;
	};

	// Function to handle card click and open link in a new tab
	const handleCardClick = (index) => {
		window.open(courseLinks[index], '_blank', 'noopener,noreferrer');
	};

	return (
		<section className="course-section">
			<h2>Our Courses</h2>
			<div className="course-carousel">
				{courses.map((course, index) => (
					<div key={index} className="course-card" onClick={() => handleCardClick(index)}>
						<img src={course.image} alt={course.title} onError={handleImageError} />
						<div className="course-info">
							<h3>{course.title}</h3>
							<p>{course.description}</p>
						</div>
					</div>
				))}
			</div>
		</section>
	);
};

export default CourseComponent;

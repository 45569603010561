import React from 'react';
import './CommunityPage.css';

const CommunityPage = () => (
	<div className="community-page">
		<h1>Join the Facottry Community</h1>
		<p>
			Connect with other developers, share knowledge, and get support from the Facottry
			community.
		</p>

		<div className="community-link">
			<h2>Facottry Discord Server</h2>
			<p>
				Join our Discord server to chat with other Facottry users and developers in
				real-time.
			</p>
			<a href="https://discord.gg/facottry">Join now</a>
		</div>

		<div className="community-link">
			<h2>Facottry GitHub Repository</h2>
			<p>
				Contribute to the Facottry project on GitHub. Report issues, submit pull requests,
				and collaborate with the community.
			</p>
			<a href="https://github.com/facottry">Visit GitHub</a>
		</div>

		<div className="community-link">
			<h2>Facottry Forum</h2>
			<p>
				Join the conversation in our forum. Ask questions, share tips, and learn from other
				Facottry users.
			</p>
			<a href="https://forum.facottry.com">Visit Forum</a>
		</div>
	</div>
);

export default CommunityPage;

import React from 'react';

function FeaturesSection() {
	const features = [
		{ title: 'Feature Flags', description: 'Manage feature flags easily.', icon: './flow.jpg' },
		{
			title: 'Remote Configuration',
			description: 'Configure settings remotely.',
			icon: './flow.jpg',
		},
		{
			title: 'Segmentation',
			description: 'Target specific user segments.',
			icon: './flow.jpg',
		},
		{
			title: 'A/B and Multivariate Testing',
			description: 'Conduct thorough tests.',
			icon: './flow.jpg',
		},
		{
			title: 'Role-Based Access and Security',
			description: 'Ensure secure access.',
			icon: './flow.jpg',
		},
	];

	return (
		<section className="features-section">
			<h2>Why Choose Facottry?</h2>
			<div className="features-list">
				{features.map((feature, index) => (
					<div key={index} className="feature-item">
						<img src={feature.icon} alt={feature.title} />
						<h3>{feature.title}</h3>
						<p>{feature.description}</p>
					</div>
				))}
			</div>
		</section>
	);
}

export default FeaturesSection;

import React from 'react';
import './PrivacyPolicyPage.css';

const PrivacyPolicyPage = () => {
	return (
		<div className="privacy-policy-container">
			<h1>Privacy Policy</h1>
			<p>Last updated: August 4, 2024</p>

			<section>
				<h2>Introduction</h2>
				<p>
					Welcome to Facottry! We are committed to protecting your personal information
					and your right to privacy. This Privacy Policy explains how we collect, use, and
					share information about you when you use our website.
				</p>
			</section>

			<section>
				<h2>Information We Collect</h2>
				<p>
					We collect information that you provide to us directly, such as when you create
					an account, fill out a form, or communicate with us. We also collect information
					automatically as you navigate through our site, such as usage data, IP
					addresses, and cookies.
				</p>
			</section>

			<section>
				<h2>How We Use Your Information</h2>
				<p>
					We use the information we collect to operate and maintain our website, improve
					our services, understand user behavior, communicate with you, and comply with
					legal obligations.
				</p>
			</section>

			<section>
				<h2>Sharing Your Information</h2>
				<p>
					We do not share your personal information with third parties except in the
					following cases: with your consent, to comply with laws, to provide services
					through third-party providers, and to protect our rights.
				</p>
			</section>

			<section>
				<h2>Your Rights and Choices</h2>
				<p>
					You have the right to access, correct, or delete your personal information. You
					may also have the right to object to or restrict certain types of processing. To
					exercise these rights, please contact us at privacy@facottry.com.
				</p>
			</section>

			<section>
				<h2>Data Security</h2>
				<p>
					We implement appropriate technical and organizational measures to protect your
					personal information from unauthorized access, use, or disclosure.
				</p>
			</section>

			<section>
				<h2>Children's Privacy</h2>
				<p>
					Our website is not intended for children under the age of 13. We do not
					knowingly collect personal information from children under 13. If we become
					aware that we have collected personal information from a child under 13, we will
					take steps to delete such information.
				</p>
			</section>

			<section>
				<h2>Changes to This Privacy Policy</h2>
				<p>
					We may update this Privacy Policy from time to time. We will notify you of any
					changes by posting the new Privacy Policy on this page. You are advised to
					review this Privacy Policy periodically for any changes.
				</p>
			</section>

			<section>
				<h2>Contact Us</h2>
				<p>
					If you have any questions about this Privacy Policy, please contact us at
					privacy@facottry.com.
				</p>
			</section>
		</div>
	);
};

export default PrivacyPolicyPage;

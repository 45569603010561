import { GrowthBook } from '@growthbook/growthbook-react';

//pass Attributes to GrowthBook
let userAgent = navigator.userAgent;
//check for Edge before Chrome and default is Chrome
let browser = 'chrome';
if (userAgent.indexOf('Edg') > -1) {
	browser = 'edge';
}

const growthbook = new GrowthBook({
	apiHost: 'https://cdn.growthbook.io',
	clientKey: 'sdk-q8f2Obq0uZradGQ',
	enableDevMode: true,
	trackingCallback: (experiment, result) => {
		// TODO: Use your real analytics tracking system
		console.log('Viewed Experiment', {
			experimentId: experiment.key,
			variationId: result.key,
		});
	},
});

growthbook.setAttributes({
	browser: browser,
});

export { growthbook };

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: 'AIzaSyAQIli_IZKvP7wJ3Cw2i4DUZRE85BLz5G8',
	authDomain: 'facottry-landing-site.firebaseapp.com',
	projectId: 'facottry-landing-site',
	storageBucket: 'facottry-landing-site.appspot.com',
	messagingSenderId: '474162403028',
	appId: '1:474162403028:web:d060a51bf7413f71520d41',
	measurementId: 'G-WRJY7W4P89',
};

// Initialize Firebase
const firebaseAppFacottry = initializeApp(firebaseConfig);
const firebaseAnalyticsFacottry = getAnalytics(firebaseAppFacottry);

export { firebaseAppFacottry, firebaseAnalyticsFacottry };

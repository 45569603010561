import React, { useEffect } from 'react';
import { useState } from 'react';
import './NotesPage.css';

import EditorJS from '@editorjs/editorjs';

const NotesPage = ({ facottryServerConfigState }) => {
	const editor = new EditorJS({
		/**
		 * onReady callback
		 */
		onReady: () => {
			console.log('Editor.js is ready to work!');
		},

		/**
		 * onChange callback
		 */
		onChange: (api, event) => {
			console.log("Now I know that Editor's content changed!", event);
		},
		holder: 'editorjs',
	});

	return (
		<div className="notes-page">
			<h1>Notes App</h1>
			<p>
				Notes App is a simple note-taking application built using React. Users can create,
				edit, and delete notes, and the app saves the notes to local storage.
			</p>
			<div id="editorjs"></div>
		</div>
	);
};

export default NotesPage;

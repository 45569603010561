import React from 'react';
import './DocsPage.css';

const DocsPage = () => (
	<div className="docs-page">
		<h1>Facottry Documentation</h1>
		<p>
			Welcome to the Facottry documentation. Here you'll find everything you need to
			effectively use Facottry for feature flag management and remote configuration.
		</p>

		<h2>Getting Started with Facottry</h2>
		<p>
			Learn the basics of setting up and using Facottry. From initial setup to creating your
			first feature flag, this section will guide you through the essential steps.
		</p>

		<h2>Advanced Features of Facottry</h2>
		<p>
			Explore the advanced capabilities of Facottry, including feature flags, remote
			configuration, A/B testing, and more. Unlock the full potential of your development
			workflow.
		</p>

		<h2>API Reference</h2>
		<p>
			Access detailed information about the Facottry API, including endpoints, request
			parameters, and examples. Integrate Facottry seamlessly into your existing systems.
		</p>
	</div>
);

export default DocsPage;

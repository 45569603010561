import React, { useEffect } from 'react';
import { useState } from 'react';
import './CareerPage.css';
import defaultJobs from './defaultJobs';

const CareerPage = ({ facottryServerConfigState }) => {
	const CareerPageData = facottryServerConfigState.CareerPage;
	const [allJobs, setAllJobs] = useState(defaultJobs);

	const [theme, setTheme] = useState('light');

	useEffect(() => {
		if (CareerPageData.allJobs) {
			setAllJobs(CareerPageData.allJobs);
		}
		if (CareerPageData.theme) {
			setTheme(CareerPageData.theme);
		}
	}, [CareerPageData]);

	function renderEachJob(eachJob) {
		let jobUI = null;
		try {
			jobUI = (
				<div key={eachJob.position} className="career-listing">
					<h2>{eachJob.position}</h2>
					<p>
						<strong>Location:</strong> {eachJob.location}
					</p>
					<p>
						<strong>Duration:</strong> {eachJob.duration}
					</p>
					<h3>Responsibilities</h3>
					<ul>
						{eachJob.responsibilities.map((responsibility, index) => (
							<li key={index}>{responsibility}</li>
						))}
					</ul>
					<h3>Requirements</h3>
					<ul>
						{eachJob.requirements.map((requirement, index) => (
							<li key={index}>{requirement}</li>
						))}
					</ul>
					<p dangerouslySetInnerHTML={{ __html: eachJob.contact }}></p>
				</div>
			);
		} catch (error) {
			console.error('Error in rendering', eachJob, error);
			jobUI = <div>Error in rendering</div>;
		}
		return jobUI;
	}

	return (
		//add the theme class to the parent div to change the theme
		<div className={`career-page ${theme}`}>
			<div className="career-header">
				<h1>welcome to {CareerPageData.name}</h1>
				<h1>Join Our Team</h1>
				<p>We are looking for passionate individuals to join our growing team.</p>
			</div>

			<div className="career-listings">{allJobs.map(renderEachJob)}</div>
		</div>
	);
};

export default CareerPage;

import React from 'react';
import './Demo.css';
import { Link } from 'react-router-dom';

const Demo = () => {
	return (
		<div className="demo">
			<h1>Interactive Demo</h1>
			<p>Experience Facottry in action with our interactive demo.</p>
			<div className="demo-content">
				{/* Add your interactive demo content here */}
				<p>
					Here you can include interactive elements or a guided tour of Facottry’s
					features.
				</p>
				{/* Example interactive element */}
				<button className="demo-button">
					{' '}
					<Link to="https://facottrywebsite.vercel.app/">Start Demo</Link>{' '}
				</button>
			</div>
		</div>
	);
};

export default Demo;

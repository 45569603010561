// App.js or your router file
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { firebaseAnalyticsFacottry, firebaseAppFacottry } from './FirebaseManager';
import './App.css';

import Header from './components/Header.js';
import HomePage from './pages/HomePage';
import FeaturesPage from './pages/FeaturesPage';
import PricingPage from './pages/PricingPage';
import AboutUsPage from './pages/AboutUsPage';
import ContactUsPage from './pages/ContactUsPage';
import SolutionsPage from './pages/SolutionsPage';
import ResourcesPage from './pages/ResourcesPage';
import StartFreeTrial from './pages/StartFreeTrial';
import Demo from './pages/Demo';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import DocsPage from './pages/DocsPage';
import BlogPage from './pages/BlogPage';
import CaseStudiesPage from './pages/CaseStudiesPage';
import EventsWebinarsPage from './pages/EventsWebinarsPage';
import InteractiveDemoPage from './pages/InteractiveDemoPage';
import CommunityPage from './pages/CommunityPage';
import CareerPage from './pages/CareerPage';

import ChatBot from './components/ChatBot';
import Footer from './components/FooterComponent';
import {
	FacottryManager,
	facottryDefaultServerConfig,
	facottryDefaultClientConfig,
} from './FacottryManager';

import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { growthbook } from './GrowthManager/index.js';
import NotesPage from './pages/NotesPage/index.js';

const App = () => {
	const [isFacottryLoaded, setIsFacottryLoaded] = useState(false);
	const [facottryServerConfigState, setFacottryServerConfigState] = useState(
		facottryDefaultServerConfig
	);

	function onFacottryLoaded(facottryServerConfig) {
		console.log('onFacottryServerConfigLoaded :: ', facottryServerConfig);
		facottryServerConfig = {
			...facottryDefaultServerConfig,
			...facottryServerConfig,
		};
		setFacottryServerConfigState(facottryServerConfig);
		setIsFacottryLoaded(true);
	}

	let USER_TYPE = {
		ALPHA: 'alpha',
		BETA: 'beta',
		DEFAULT: 'default',
	};
	//randomValue between  0 to 100

	let randomValue = Math.random() * 100;
	randomValue = Math.floor(randomValue);

	let userType =
		randomValue < 50 ? USER_TYPE.ALPHA : randomValue < 75 ? USER_TYPE.BETA : USER_TYPE.DEFAULT;

	let facottryClientConfig = {
		...facottryDefaultClientConfig,
		onFacottryLoaded: onFacottryLoaded,
	};

	facottryClientConfig = {
		...facottryClientConfig,
		filter: {
			...facottryClientConfig.filter,
			userType: userType,
			userName: 'NAVEEN',
		},
	};

	useEffect(() => {
		// Load features asynchronously when the app renders
		growthbook.init({ streaming: true });
	}, []);

	useEffect(() => {
		console.log('App :: useEffect');
		const facottryManager = new FacottryManager(facottryClientConfig);
		facottryManager.init();
		//do Not load App until Facottry is loaded
	}, []);

	return (
		<GrowthBookProvider growthbook={growthbook}>
			<Router>
				<Header />
				<Routes>
					{ (
						<Route
							path="/"
							element={
								<HomePage facottryServerConfigState={facottryServerConfigState} />
							}
						/>
					)}
					<Route
						path="/features"
						element={
							<FeaturesPage facottryServerConfigState={facottryServerConfigState} />
						}
					/>
					<Route
						path="/pricing"
						element={
							<PricingPage facottryServerConfigState={facottryServerConfigState} />
						}
					/>
					<Route
						path="/about-us"
						element={
							<AboutUsPage facottryServerConfigState={facottryServerConfigState} />
						}
					/>
					<Route
						path="/contact-us"
						element={
							<ContactUsPage facottryServerConfigState={facottryServerConfigState} />
						}
					/>
					<Route
						path="/solutions"
						element={
							<SolutionsPage facottryServerConfigState={facottryServerConfigState} />
						}
					/>
					<Route
						path="/resources"
						element={
							<ResourcesPage facottryServerConfigState={facottryServerConfigState} />
						}
					/>
					<Route
						path="/start-free-trial"
						element={
							<StartFreeTrial facottryServerConfigState={facottryServerConfigState} />
						}
					/>
					<Route
						path="/demo"
						element={<Demo facottryServerConfigState={facottryServerConfigState} />}
					/>
					<Route
						path="/privacy-policy"
						element={
							<PrivacyPolicyPage
								facottryServerConfigState={facottryServerConfigState}
							/>
						}
					/>
					<Route
						path="/careers"
						element={
							<CareerPage facottryServerConfigState={facottryServerConfigState} />
						}
					/>
					<Route
						path="/docs"
						element={<DocsPage facottryServerConfigState={facottryServerConfigState} />}
					/>
					<Route
						path="/blog"
						element={<BlogPage facottryServerConfigState={facottryServerConfigState} />}
					/>
					<Route
						path="/case-studies"
						element={
							<CaseStudiesPage
								facottryServerConfigState={facottryServerConfigState}
							/>
						}
					/>
					<Route
						path="/events-webinars"
						element={
							<EventsWebinarsPage
								facottryServerConfigState={facottryServerConfigState}
							/>
						}
					/>
					<Route
						path="/interactive-demo"
						element={
							<InteractiveDemoPage
								facottryServerConfigState={facottryServerConfigState}
							/>
						}
					/>
					<Route
						path="/community"
						element={
							<CommunityPage facottryServerConfigState={facottryServerConfigState} />
						}
					/>
					<Route
						path="/notes"
						element={
							<NotesPage facottryServerConfigState={facottryServerConfigState} />
						}
					/>
				</Routes>
				<ChatBot />
				<Footer />
			</Router>
		</GrowthBookProvider>
	);
};

export default App;

const defaultJobs = [
	{
		position: 'Marketing Intern',
		location: 'Remote',
		duration: '3 months',
		responsibilities: [
			'Assist in developing and implementing marketing strategies.',
			'Conduct market research and analyze trends.',
			'Help manage social media accounts and campaigns.',
			'Create content for marketing materials and social media posts.',
		],
		requirements: [
			'Currently pursuing a degree in Marketing or related field.',
			'Strong written and verbal communication skills.',
			'Familiarity with social media platforms and marketing tools.',
			'Creative mindset and attention to detail.',
		],
		contact: `Interested candidates can send their resumes to <a href='mailto:facottry@gmail.com'>`,
	},
];
export default defaultJobs;

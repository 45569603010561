import React, { useState } from 'react';
import axios from 'axios';
import './ContactUsPage.css';

const ContactUsPage = () => {
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		message: '',
	});

	const [status, setStatus] = useState('');

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setStatus('Sending...');

		try {
			const response = await axios.post(
				'https://facottry-landing-site.web.app/sendEmail',
				formData
			);
			if (response.status === 200) {
				console.log('axios.post Success :: ', response);
				setStatus('Email sent successfully!');
				setFormData({ name: '', email: '', message: '' }); // Reset form
			} else {
				console.error('axios.post Fail 1:: ', response);
				setStatus('Failed to send email. Please try again.');
			}
		} catch (error) {
			console.error('axios.post Fail 2:: ', error);
			setStatus('Failed to send email. Please check your network connection.');
		} finally {
			//setLoading(false);
		}
	};

	// <form onSubmit={handleSubmit}>
	// 	<div className="form-group">
	// 		<label htmlFor="name">Name:</label>
	// 		<input
	// 			type="text"
	// 			id="name"
	// 			name="name"
	// 			value={formData.name}
	// 			onChange={handleChange}
	// 			required
	// 		/>
	// 	</div>
	// 	<div className="form-group">
	// 		<label htmlFor="email">Email:</label>
	// 		<input
	// 			type="email"
	// 			id="email"
	// 			name="email"
	// 			value={formData.email}
	// 			onChange={handleChange}
	// 			required
	// 		/>
	// 	</div>
	// 	<div className="form-group">
	// 		<label htmlFor="message">Message:</label>
	// 		<textarea
	// 			id="message"
	// 			name="message"
	// 			value={formData.message}
	// 			onChange={handleChange}
	// 			required></textarea>
	// 	</div>
	// 	<button type="submit">Send</button>
	// </form>;
	return (
		<div className="contact-page">
			<h2>Contact Us</h2>

			<div className="contact-form">
				<iframe
					width="540"
					height="305"
					src="https://d149baee.sibforms.com/serve/MUIFAJ3ypdNmDMnSylNuatoRqYYSkswtAvnclEw8knE2aOk0O6ORTWs1OMSJTr8LIRwsSSUpppz4iHmmuGfwrjUmSG87uXGZEUkLBMmkViC1e4Zm3iX_yRR9bAkBm1pU9CW18bdB6EGDjKMgdRSwc8D6lUMgeLtPCx-doUwvdrDCICWsf4GdgWsWCRhFSGw1ad9Wil7OQ_bYfaNs"
					frameborder="0"
					scrolling="auto"
					allowfullscreen
					style={{ display: 'block', margin: '0 auto' }}></iframe>
			</div>
		</div>
	);
};

export default ContactUsPage;

import CareerPage from '../pages/CareerPage';
import defaultJobs from '../pages/CareerPage/defaultJobs';
import HomePage from '../pages/HomePage';

class FacottryManager {
	constructor(facottryClientConfig = {}) {
		//convert all filter to big case
		facottryClientConfig.filter = this.convertCase(facottryClientConfig.filter);
		this.facottryClientConfig = {
			projectID: facottryClientConfig.projectID || '',
			filter: facottryClientConfig.filter || {},
			onFacottryLoaded_App: facottryClientConfig.onFacottryLoaded || function () {},
			onFacottryLoaded: this.onFacottryLoaded,
		};
	}

	convertCase = (obj) => {
		let newObj = {};
		for (let key in obj) {
			//key and value both are in upper case
			newObj[key.toUpperCase()] = obj[key].toUpperCase();
		}
		return newObj;
	};

	onFacottryLoaded = (facottryServerConfig) => {
		if (!this.responseReceived) {
			console.log('FacottryManager :: onFacottryLoaded :: ', facottryServerConfig);
			this.responseReceived = true;
			this.handleABSegmentation(facottryServerConfig);
			this.facottryClientConfig.onFacottryLoaded_App(facottryServerConfig);
		}
	};

	handleABSegmentation = (facottryServerConfig) => {
		if (facottryServerConfig.AB_SEGMENT) {
			let AB_SEGMENT_CONFIG = facottryServerConfig.AB_SEGMENT;
			let SEGMENT_LIST = AB_SEGMENT_CONFIG.SEGMENT_LIST;
			let selectedSegment = localStorage.getItem('selectedSegment');
			if (selectedSegment && SEGMENT_LIST.includes(selectedSegment)) {
				console.log('selectedSegment', selectedSegment);
			} else {
				let totalSegment = SEGMENT_LIST.length;
				let newSegmentIndex = Math.floor(Math.random() * totalSegment);
				let selectedSegment = SEGMENT_LIST[newSegmentIndex];
				localStorage.setItem('selectedSegment', selectedSegment);
			}
		}
	};

	handleAutoSegmentation = (filter) => {
		let lastSessionSegment = localStorage.getItem('selectedSegment');
		if (lastSessionSegment) {
			filter['AB_SEGMENT'] = lastSessionSegment;
		} else {
			filter['AB_SEGMENT'] = 'DEFAULT';
		}
		return filter;
	};

	init() {
		if (typeof window.FACOTTRY != 'undefined') {
			this.handleAutoSegmentation(this.facottryClientConfig.filter);
			window.FACOTTRY.init(this.facottryClientConfig);
		}
	}

	getFactory(name) {
		return this.factory[name];
	}
}

const facottryDefaultServerConfig = {
	CareerPage: {
		name: 'Career Page',
		allJobs: defaultJobs,
	},
	HomePage: {
		HeroSection: {
			heading: 'Effortless Feature Flag Management for Agile Development Teams',
			description:
				'Facottry empowers development teams to manage feature flags seamlessly, enabling you to toggle features, deploy confidently, and accelerate your development cycle. Our platform provides robust tools for feature management, real-time configuration changes, and in-depth analytics to optimize your software delivery process.',
			buttons: [
				{
					label: 'Get Started',
					link: '/start-free-trial',
				},
				{
					label: 'Request a Demo',
					link: '/start-free-trial',
				},
			],
			image: './hero-image.jpg',
		},
		FeaturesSection: {
			features: [
				{
					title: 'Feature Flags',
					description: 'Manage feature flags easily.',
					icon: './flow.jpg',
				},
				{
					title: 'Remote Configuration',
					description: 'Configure settings remotely.',
					icon: './flow.jpg',
				},
				{
					title: 'Segmentation',
					description: 'Target specific user segments.',
					icon: './flow.jpg',
				},
				{
					title: 'A/B and Multivariate Testing',
					description: 'Conduct thorough tests.',
					icon: './flow.jpg',
				},
				{
					title: 'Role-Based Access and Security',
					description: 'Ensure secure access.',
					icon: './flow.jpg',
				},
			],
		},
		HowItWorks: {
			steps: [
				{
					title: 'Integrate Facottry',
					description: 'Integrate with your existing workflow.',
					icon: './flow.jpg',
				},
				{
					title: 'Manage Feature Flags',
					description: 'Create and manage feature flags and configurations.',
					icon: './flow.jpg',
				},
				{
					title: 'Monitor Performance',
					description: 'Monitor and analyze feature performance.',
					icon: './flow.jpg',
				},
			],
		},
		Testimonials: {
			testimonials: [
				{
					name: 'John Doe',
					designation: 'Software Engineer',
					quote: 'Facottry has been a game-changer for our development process. We can now deploy features with confidence and monitor performance in real-time.',
				},
				{
					name: 'Jane Smith',
					designation: 'Product Manager',
					quote: "Facottry's feature management tools have helped us streamline our development process and optimize our software delivery cycle.",
				},
			],
		},
		CallToAction: {
			heading: 'Ready to get started?',
			description: 'Sign up for a free trial today and experience the power of Facottry.',
			buttonLabel: 'Start Free Trial',
			buttonLink: '/start-free-trial',
		},
	},
};

const facottryDefaultClientConfig = {
	projectID: 'landing_site_c371ef8e-2166-4447-96e2-5b649e5f2868',
	filter: {
		COUNTRY: 'IN',
		userType: 'default',
	},
};

export { FacottryManager, facottryDefaultServerConfig, facottryDefaultClientConfig };

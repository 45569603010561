// ResourcesPage.js
import React from 'react';
import './ResourcesPage.css';

const ResourcesPage = () => {
	return (
		<div className="resources-page">
			<header className="resources-header">
				<h1>Resources</h1>
				<p>Everything you need to get started and master Facottry</p>
			</header>

			<section className="resources-list">
				<div className="resource-item">
					<h2>Documentation</h2>
					<p>
						Explore our comprehensive documentation to learn about all the features and
						capabilities of Facottry.
					</p>
					<a href="/docs" className="resource-link">
						Read Documentation
					</a>
				</div>

				<div className="resource-item">
					<h2>Blog</h2>
					<p>Stay updated with the latest news, tips, and tutorials from our blog.</p>
					<a href="/blog" className="resource-link">
						Visit Blog
					</a>
				</div>

				<div className="resource-item">
					<h2>Case Studies</h2>
					<p>
						Discover how other teams have successfully used Facottry to achieve their
						goals.
					</p>
					<a href="/case-studies" className="resource-link">
						View Case Studies
					</a>
				</div>

				<div className="resource-item">
					<h2>Events & Webinars</h2>
					<p>
						Join us for live events and webinars to learn more about Facottry and
						network with industry professionals.
					</p>
					<a href="/events-webinars" className="resource-link">
						Explore Events
					</a>
				</div>

				<div className="resource-item">
					<h2>Interactive Demo</h2>
					<p>
						Experience Facottry in action with our interactive demo and see how it can
						benefit your team.
					</p>
					<a href="/interactive-demo" className="resource-link">
						Try the Demo
					</a>
				</div>

				<div className="resource-item">
					<h2>Community</h2>
					<p>Connect with other Facottry users and get support from our community.</p>
					<a href="/community" className="resource-link">
						Join the Community
					</a>
				</div>
			</section>
		</div>
	);
};

export default ResourcesPage;
